@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: #ff9a76;
  --color-secondary: #ffd7c8;
  --color-tertiary: #ff6d38;

  --color-grey-0: #fff;
  --color-grey-50: #f9fafb;
  --color-grey-100: #f3f4f6;
  --color-grey-200: #e5e7eb;
  --color-grey-300: #d1d5db;
  --color-grey-400: #9ca3af;
  --color-grey-500: #6b7280;
  --color-grey-600: #4b5563;
  --color-grey-700: #374151;
  --color-grey-800: #1f2937;
  --color-grey-900: #111827;
}

#root {
  display: grid;
  grid-template-columns: 15vw 50vw 15vw;
  gap: 1rem;
  align-items: start;
  justify-content: center;
  height: 100vh;
  padding: 5rem;
  background-image: url("../../public/bg.png");
  background-size: cover;
}

::selection {
  background-color: var(--color-primary);
  color: var(--color-grey-0);
}

@media screen and (max-width: 1000px) {
  #root {
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content 53dvh;
    padding: 1rem;
  }
}
